import React from 'react'
import { graphql } from 'gatsby'
import BlogContent from '../components/BlogContent'

export default props => {
  return <BlogContent {...props} />
}

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            tags
            description
          }
        }
      }
    }
  }
`
